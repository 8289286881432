html{
    scroll-behavior: initial !important;
}

*{
    text-decoration: none;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}

a,a:hover{
    text-decoration: none;
    color: var(--white);
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../fonts/Montserrat-SemiBold.ttf");
    font-weight: 600;
}

/* --- Website Fonts --- */
@font-face {
    font-family: 'Gill Sans';
    src: url("../fonts/gillsans/Gill-Sans-Light.otf");
    font-weight: 300;
}

@font-face {
    font-family: 'Gill Sans';
    src: url("../fonts/gillsans/Gill-Sans.otf");
    font-weight: 400;
}

@font-face {
    font-family: 'Gill Sans';
    src: url("../fonts/gillsans/Gill-Sans-Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: 'Gill Sans';
    src: url("../fonts/gillsans/Gill-Sans-Heavy.otf");
    font-weight: 600;
}

@font-face {
    font-family: 'Gill Sans';
    src: url("../fonts/gillsans/Gill-Sans-Bold.otf");
    font-weight: 700;
}


/* --- Body tag Css --- */
body{
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    color: var(--color1);
    background-color: var(--white);
}

.relative{
    position: relative;
}

.overflowhidden,
body.overflowhidden{
    overflow: hidden;
}


/* --- Web Loader Css --- */
.web-preloader {
    background-color: var(--background-color3);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 2000;
}

.web-preloader span.web-inner {
    width: 200px;
    height: 200px;
    line-height: 200px;
    margin: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    text-align: center;
    z-index: 0;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: var(--white);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.web-preloader span.web-inner img {
    width: 100px;
    height: 100px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
    position: relative;
    -webkit-animation: gogoloader-2 5ms infinite;
       -moz-animation: gogoloader-2 5ms infinite;
         -o-animation: gogoloader-2 5ms infinite;
            animation: gogoloader-2 5ms infinite;
}

@-webkit-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@-moz-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

@-o-keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -o-transform: scale(0);
           transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -o-transform: scale(1.2);
           transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes gogoloader {
    0% {
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
          -o-transform: scale(0);
             transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
          -o-transform: scale(1.2);
             transform: scale(1.2);
        opacity: 0;
    }
}

.deactivate span.web-inner {
    opacity: 0;
    visibility: hidden;
    /* -webkit-transform: translateY(50px);
       -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
         -o-transform: translateY(50px);
            transform: translateY(50px); */
    -webkit-transition: all 0.3s 0.5s ease-in-out;
    -o-transition: all 0.3s 0.5s ease-in-out;
    -moz-transition: all 0.3s 0.5s ease-in-out;
    transition: all 0.3s 0.5s ease-in-out;
}

.deactivate {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s 1s ease-in-out;
    -o-transition: all 0.3s 1s ease-in-out;
    -moz-transition: all 0.3s 1s ease-in-out;
    transition: all 0.3s 1s ease-in-out;
}


/* --- main-wrapper --- */
.main-wrapper{
    min-height: -webkit-calc(100vh - 80px - 328px);
    min-height: -moz-calc(100vh - 80px - 328px);
    min-height: calc(100vh - 80px - 328px);
}

@media screen and (max-width:992px) {
    .main-wrapper{
        min-height: -webkit-calc(100vh - 80px - 442px);
        min-height: -moz-calc(100vh - 80px - 442px);
        min-height: calc(100vh - 80px - 442px);
    }
}


/* --- Common max width --- */
.maxwidth1920{
    max-width: 1920px;
    margin: auto;
}

/* --- Bootsrep Overaid Css Start --- */
.container-lg.cl-custome{
    max-width: 1140px;
}
/* --- Bootsrep Overaid Css End --- */

/* --- Social Share Icon & Social Icons start --- */
.social-icon{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 20px;
}

.social-icon{
    padding-bottom: 0;
}

.social-icon .si-item{
    padding: 0px 3px;
    text-align: center;
    cursor: pointer;
}

.social-icon .si-item .si-icon{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 40px;
    height: 40px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    overflow: hidden;
    margin: auto;
    font-size: 16px;
    color: var(--color2);
    border: 1px solid var(--border-color2);
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.social-icon .si-item .si-icon .fa{
    width: 20px;
    height: 20px;
    position: relative;
    top: 0px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.social-icon .si-item:hover .si-icon{
    color: var(--white);
    background-color: var(--background-color1);
    border-color: var(--border-color1);
}



/* --- home-main-section start --- */

.home-main-section{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 56%;
    overflow: hidden;
}

.hms-affter{
    margin-top: -24px;
}

@media screen and (min-width:992px) {
    .home-main-section{
        padding-top: 36%;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
    .home-main-section{
        padding-top: 50%;
    }
}

@media (max-width:576px) {
    .home-main-section{
     margin-top: -7%;
    }
}

/* --- home-main-section End --- */



/* --- common page bg bx Start --- */
.common-page-bg-bx{
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    overflow: hidden;
    pointer-events: none;
    transition: all 1s ease-in-out;
}

.common-page-bg-bx:after {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.common-page-bg-bx img{
    max-width: 1920px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: auto;
    display: block;
    transition: all 0.3s ease-in-out;
    filter: grayscale(1);
}

.common-page-bg-bx video{
    max-width: 1920px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    margin: auto;
    display: block;
    transition: all 0.3s ease-in-out;
}

.cpbg-op0{
    opacity: 0;
}

.cpbg-op1{
    opacity: 1;
}

/* --- common page bg bx End --- */


/* --- Common Heading Bx Css Start --- */
.common-heading-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
}

.common-heading-bx.chb-fd-row{
    gap: 15px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
       -moz-box-orient: horizontal;
       -moz-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

.common-heading-bx .common-heading{
    font-size: 24px;
    color: #1d1d1f;
    font-weight: 600;
}

.common-heading-bx .common-heading.ch-big{
    font-size: 36px;
}

.common-heading-bx .common-heading .ch-size-big{
    font-size: 140%;
}

.common-heading-bx .common-sub-heading{
    font-size: 16px;
    color: #1d1d1f;
}


@media screen and (min-width:768px) {
    .common-heading-bx.chb-white-768-max .common-heading{
        color: #fff;
    }
    .common-heading-bx.chb-white-768-max .common-sub-heading{
        color: #fff;
        opacity: 0.7;
    }
}


@media screen and (max-width:768px) {
    /* .common-heading-bx .common-sub-heading br,
    .common-heading br{
        display: none;
    } */

    .common-heading-bx.chb-fd-row{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: inherit;
        -webkit-justify-content: inherit;
           -moz-box-pack: inherit;
            -ms-flex-pack: inherit;
                justify-content: inherit;
        /* align-items: inherit; */
    }
}

@media screen and (max-width:992px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 32px;
    }
}

@media screen and (max-width:768px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 28px;
    }

    .common-heading-bx .common-heading{
        font-size: 20px;
    }

    .common-heading-bx .common-sub-heading{
        font-size: 14px;
    }
}

@media screen and (max-width:576px) {
    .common-heading-bx .common-heading.ch-big{
        font-size: 20px;
    }
}
/* --- Common Heading Bx Css Start --- */


/* --- Common Button Css Start --- */
.common-btn-item{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 100px;
       -moz-border-radius: 100px;
            border-radius: 100px;
    height: 36px;
    padding: 0 15px;
    font-size: 14px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: var(--color1);
    font-weight: 500;
    letter-spacing: 1px;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.common-btn-item.cbi-fill{
    color: var(--white);
    background-color: var(--background-color1);
    border-color: var(--border-color1);
}

.common-btn-item.cbi-fill.cbi-fill-white{
    color: var(--color1);
    font-weight: 600;
    background-color: var(--white);
    border-color: var(--white);
}

.common-btn-item.cbi-outline{
    color: var(--color1);
    border-color: var(--color1);
}

.common-btn-item.cbi-outline.cbi-outline-white{
    color: var(--white);
    border-color: var(--white);
}


@media only screen and (min-width: 768px) {

    .common-btn-item.cbi-fill:hover{
        color: var(--color1);
        background-color: transparent;
        border-color: var(--border-color1);
    }

    
    .common-btn-item.cbi-fill.cbi-fill-white:hover{
        color: var(--white);
        background-color: transparent;
        border-color: var(--border-white);
    }

    .common-btn-item.cbi-outline:hover{
        color: var(--white);
        background-color: var(--background-color1);
        border-color: var(--border-color1);
    }

    
    .common-btn-item.cbi-outline.cbi-outline-white:hover{
        color: var(--color1);
        border-color: var(--border-white);
        background-color: var(--background-white);
    }
    
}
/* --- Common Button Css End --- */


/* --- common date style css start --- */
.common-img-bx img{
    width: 100%;
}

.common-date-text{
    font-size: 18px;
    color: var(--color2);
}

.common-date-text2{
    font-size: 16px;
    color: var(--color2);
}

.common-date-ul{
    color: var(--color2);
    padding: 0;
    list-style: none;
}

.common-date-ul li{
    position: relative;
    color: var(--color2);
    padding: 5px 0px;
    padding-left: 20px;
}

.common-date-ul li::after{
    content: "";
    position: absolute;
    left: 2px;
    top: 12.3px;
    width: 8px;
    height: 8px;
    background-color: var(--color1);
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
}
/* --- common date style css end --- */



/* -- FAQs Section Css Start --- */
.faqs-accordion{
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
    margin-top: 20px;
    padding: 10px 5px;
}

.faqs-accordion .acc-item{
    padding: 0 0;
    border-bottom: 1px solid var(--border-color2);
}

.faqs-accordion .acc-item:last-child {
    border-bottom: none;
}

.faqs-accordion .acc-item .acc-heding{
    position: relative;
    font-size: 18px;
    padding: 15px 0px;
    padding-right: 30px;
    cursor: pointer;
    font-weight: 500;
    color: var(--color1);
}

.faqs-accordion .acc-item .acc-heding.collapsed {
    opacity: 0.9;
}

.faqs-accordion .acc-item .acc-heding.collapsed:hover {
    opacity: 1;
}

.faqs-accordion .acc-item .acc-heding span.down-icon {
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
       -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
         -o-transform: translateY(-50%);
            transform: translateY(-50%);
    background-image: url("../img/icons/Minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    -webkit-background-size: contain;
       -moz-background-size: contain;
         -o-background-size: contain;
            background-size: contain;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 26px;
    height: 26px;
    font-size: 20px;
    -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
            border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}



.faqs-accordion .acc-item .acc-heding.collapsed span.down-icon {
    -webkit-transform: translateY(-50%) rotate(180deg);
       -moz-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
         -o-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
    background-image: url("../img/icons/Plus.svg");
}

.faqs-accordion .acc-item .acc-heding span.down-icon.di-two {
    background-image: url("../img/icons/Minus2.svg");
}

.faqs-accordion .acc-item .acc-heding.collapsed span.di-two {
    background-image: url("../img/icons/Plus2.svg");
}

.faqs-accordion .acc-item .faqs-data{
    color: var(--color2);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
}

.faqs-accordion .acc-item .faqs-data .main-heading{
    margin-top: 20px;
    font-weight: 600;
    color: var(--color1);
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: -5px;
}

.faqs-accordion .acc-item .faqs-data ul li,
.faqs-accordion .acc-item .faqs-data p{
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--color2);
    font-weight: 500;
    text-align: justify;
}

.faqs-accordion .acc-item .faqs-data strong{
    color: var(--color1);
}

.faqs-accordion .acc-item .faqs-data a{
    color: var(--color2);
    text-decoration: underline;
}

.faqs-accordion  .acc-item .faqs-data a:hover{
    color: var(--color1);
}

@media screen and (max-width:576px) {
    .faqs-accordion .acc-item .acc-heding{
        font-size: 16px;
    }
}

/* -- FAQs Section Css End --- */



/* --- Contact Page Css Start --- */
.contact-info{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 10px;
    margin: 5px 0 !important;
    margin: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    overflow: hidden;
}

.contact-info::after{
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    inset: 0;
    z-index: -1;
    opacity: 0;
    background-color: var(--background-color2);
    transition: all 0.3s ease-in;
}

.contact-info:hover::after {
    opacity: 1;
}

.contact-info .contact-icon {
    padding: 2px;
    width: 40px;
    height: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 40px;
       -moz-box-flex: 0;
        -ms-flex: 0 0 40px;
            flex: 0 0 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
            border-radius: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0.8;
}

.contact-info:hover .contact-icon {
    opacity: 1;
}

.contact-info .contact-icon img {
    width: 100%;
}

.contact-info .contact-text h6 {
    color: var(--color1);
    font-weight: 600;
    font-size: 12px;
    line-height: 1.73;
    overflow: hidden;
    margin: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info .contact-text p {
    color: var(--color2);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.contact-info:hover .contact-text p {
    color: var(--color1);
    text-decoration: underline;
}
/* --- Contact Page Css End --- */


/* --- Close Btn Css Start --- */
.close-icon{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 35px;
    height: 35px;
    background: transparent;
    -webkit-border-radius: 100%;
       -moz-border-radius: 100%;
            border-radius: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    color: #1d1d1f;
    padding: 4px;
    cursor: pointer;
    -webkit-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    box-shadow: 2px 4px 12px transparent;
}

.close-icon:hover{
    -webkit-transform: rotate(360deg);
       -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
         -o-transform: rotate(360deg);
            transform: rotate(360deg);
            box-shadow: 2px 4px 12px #00000014;
}

@media screen and (min-width:700px) {
    .close-icon:hover{
        background-color: #f5f5f7;
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    .close-icon:active{
        background-color: #f5f5f7;
    }
}
/* --- Close Btn Css End --- */


/* --- Overlaydiv Css Start --- */
.serach-overlay,
.overlaydiv {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    -webkit-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    top: 0;
    cursor: pointer;
    margin: 0 !important;
    z-index: 110;
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.serach-overlay.active,
.overlaydiv.active {
    visibility: visible;
    opacity: 1;
}
/* --- Overlaydiv Css End --- */


/* --- Breadcrumb Css Start --- */
.breadcrumb-bx{   
    display: -webkit-inline-box;   
    display: -webkit-inline-flex;   
    display: -moz-inline-box;   
    display: -ms-inline-flexbox;   
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    white-space: nowrap;
    width: 100%;
}

.breadcrumb-bx .breadcrumb-link{
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
    color: var(--color2);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}

.breadcrumb-bx .breadcrumb-link.breadcrumb-active{
    font-weight: 500;
    color: var(--color2);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    pointer-events: none;
    text-decoration: none;
}

.breadcrumb-bx .breadcrumb-link:hover{
    color: var(--color1);
    cursor: pointer;
}
/* --- Breadcrumb Css End --- */



/* --- Modals Common Css Start --- */
.modal{
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
}

.modal.fade.zoom .modal-dialog{
    -webkit-transform: scale(0.9);
       -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
         -o-transform: scale(0.9);
            transform: scale(0.9);
}

.modal.zoom.show .modal-dialog {
    -webkit-transform: none;
       -moz-transform: none;
        -ms-transform: none;
         -o-transform: none;
            transform: none;
}

.modal-content.custom-content{
    background-color: white;
    -webkit-border-radius: 16px;
       -moz-border-radius: 16px;
            border-radius: 16px;
}

.custom-modal-header{
    position: -webkit-sticky;
    position: sticky;
    padding: 25px 15px 15px;
}

.custom-modal-header.cmh-px-50{
    padding: 25px 50px 15px;
}

.custom-modal-header .cmh-lable{
    font-size: 20px;
    color: #1d1d1f;
    font-weight: 600;
    text-align: center;
}

.custom-modal-header .cmh-sub-lable{
    font-size: 16px;
    color: #1d1d1f;
    font-weight: 400;
    text-align: center;
    margin-top: 5px;
}

.custom-modal-data *{
    font-size: 14px;
    color: #1d1d1f;;
}
/* --- Modals Common Css End --- */


/* --- Lagal Pages Css Start --- */
.legal-content .main-lable {
    font-weight: 600;
    margin-top: 30px;
    color: var(--color1);
    font-size: 20px;
    text-transform: capitalize;
}

.legal-content .main-sub-lable {
    margin-top: 30px;
    font-weight: 600;
    color: var(--color1);
    font-size: 18px;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.legal-content ul li,
.legal-content p {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    margin-top: 10px;
    color: var(--color2);
    font-weight: 500;
    text-align: justify;
}

.legal-content ul {
    list-style: decimal;
    padding: 0 0 0 10px;
    margin-left: 20px;
    margin-top: 10px;
}

.legal-content ul.ul-dick {
    list-style: disc;
}

.legal-content ul.ul-alpha {
    list-style: lower-alpha;
}

.legal-content ul.ul-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.legal-content ul.ul-flex li {
    width: 25%;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
       -moz-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    padding-right: 30px;
    text-align: left;
}

@media screen and (max-width:992px) {
    .legal-content ul.ul-flex li {
        width: 33.33%;
    }
}

@media screen and (max-width:768px) {
    .legal-content ul.ul-flex li {
        width: 50%;
    }
}

@media screen and (max-width:450px) {
    .legal-content ul.ul-flex li {
        width: 100%;
        padding-right: 0px;
    }
}


.legal-content ul li a,
.legal-content p a {
    text-decoration: underline;
    color: var(--color2);
}

.legal-content ul a:hover,
.legal-content p a:hover {
    color: var(--color1);
}

.legal-content ul strong,
.legal-content p strong {
    color: var(--color1);
}
/* --- Lagal Pages Css End --- */




/* --- Highlight Card Start --- */
.hc-gap{
    row-gap: 30px;
}

.highlight-card{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px 15px;
    border-radius: 20px;
    overflow: hidden;
    min-height: 88px;
    background: var(--background-color3);
    transition: all 0.3s ease-in-out;
}

.highlight-card:hover::after{
    opacity: 1;
}

.highlight-card img{
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 60px;
    height: 60px;
    object-fit: contain;
    object-position: right center;
    opacity: 0.4;
    transition: all 0.3s ease-in-out;
    transform: rotate(-20deg);
}

.highlight-card .hc-headign{
    font-size: 16px;
    font-weight: 600;
}
/* --- --- */




/* --- App Store Icons Css Start --- */
.app-store-bx{
    margin-top: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
            gap: 10px;
}

.app-store-bx .asb-item{
    opacity: 1;
    width: 40px;
    height: 40px;
    filter: grayscale(1);
    padding: 3px;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.app-store-bx .asb-item:nth-child(2){
    padding: 4px;
    position: relative;
    top: 1px;
}

.app-store-bx .asb-item img{
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
}
/* --- App Store Icons Css End --- */



/* --- Common Check list Css Start --- */
.check-list-bx{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
}

.check-list-bx .clb-item{
    align-items: center;
    color: #1d1d1f;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    gap: 5px;
}

.check-list-bx .clb-item .clb-item-icon{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    color: #1d1d1f;
    padding: 0px;
    background: white;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-list-bx .clb-item .clb-item-icon svg{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}



/* --- Common Check list Css End --- */


/* --- Store Section :- App Download Bx Start --- */
.app-download-bx{
    border-radius: 20px;
    background: url("../img/bgimg/world-bg.webp"), #f5f5f7 ;
    background-position: center;
    background-repeat: no-repeat;
}

.app-download-bx .app-download-inner{
    width: calc(100% - 360px);
}

.app-download-bx > .adbbanner-img{
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 325px;
    height: calc(100% + 30px);
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width:768px) {
    .app-download-bx{
        background-position: center bottom;
        overflow: hidden;
    }

    .app-download-bx .app-download-inner{
        width: initial;
    }

    .app-download-bx > .adbbanner-img{
        position: relative;
        right: initial;
        bottom: -25px;
        width: 220px;
        height: 220px;
        margin-top: 25px;
    }
}
/* --- Store Section :- App Download Bx End --- */
.grayscale-img{
    filter: grayscale(1);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.ynimation{
    animation: mover 3s infinite alternate;
}

@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}


.bfgndtg{
    background-color: var(--background-color2);
}


.widthfull{
    max-width: 450px;
    width: 100%;
    height: 44px;
    margin: auto;
    font-size: 16px;
}

@media screen and (max-width:576px) {
    .width576full{
        width: 100%;
    }
}




/* common-tabing-bx start */
.common-tabing-bx{
    position: relative;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 1px;
}

.common-tabing-bx::after{
    content: " ";
    position: absolute;
    left: 0;
    top: calc(100% - 1px);
    width: 100%;
    height: 1px;
    background-color: var(--border-color2);
}

.common-tabing-bx::-webkit-scrollbar{
    display: none;
}

.common-tabing-bx .ctb-item{
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: var(--color2);
    padding: 10px 10px;
    font-weight: 500;
    font-size: 18px;
    margin: 0 5px;
}

.common-tabing-bx .ctb-item::after{
    content: " ";
    position: absolute;
    left: 0;
    top: calc(100% + 0px);
    width: 100%;
    height: 1px;
    background-color: var(--background-color1);
    opacity: 0;
    z-index: 1;
}

.common-tabing-bx .ctb-item:hover,
.common-tabing-bx .ctb-item.active{
    color: var(--color1);
}

.common-tabing-bx .ctb-item.active::after{
    opacity: 1;
}

@media screen and (max-width:576px) {
    .common-tabing-bx .ctb-item{
        font-size: 14px;
        padding: 10px 5px;
    }
}

/* common-tabing-bx end */





.pricing-main-bx{
    cursor: pointer;
    border-radius: 20px;
    background-color: var(--background-color3);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-shadow: 0 0 20px -5px #00000008;
    border: 1px solid var(--border-color2);
}

/* .pricing-main-bx .common-btn-item{
    pointer-events: none;
} */

.active > .pricing-main-bx{
    border-color: var(--border-color1);
    background-color: var(--background-white);
}

/* .active > .pricing-main-bx .common-btn-item{
    pointer-events: initial;
} */

.pricing-main-bx .pmb-item{
    display: flex;
    flex-direction: column;
}

.pricing-main-bx .pmb-item .pmb-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #1d1d1f;
    font-weight: 600;
}

.pricing-main-bx .pmb-item .pmb-heading span{
    color: var(--color2);
    font-weight: 500;
    font-size: 14px;
}

.pricing-main-bx .pmb-item .pmb-data-price{
    font-size: 14px;
    color: var(--color2);
    font-weight: 500;
    margin-top: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pricing-main-bx .pmb-item .pmb-data-price span{
    color: var(--color2);
    font-weight: 500;
    font-size: 14px;
}



.pricing-included-heading{
    font-size: 18px;
    color: #1d1d1f;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: -5px;
}

.pricing-included-bx.check-list-bx .clb-item {
    font-size: 14px;
    color: var(--color2);
    width: calc(50% - 8px);
    flex: 0 0 calc(50% - 8px);
    font-weight: 500;
    gap: 10px;
}

.pricing-included-bx.check-list-bx .clb-item .clb-item-icon{
    color: var(--color1);
    width: 24px;
    height: 24px;
    padding: 4px;
    background-color: var(--background-color3);
}

.pricing-row{
    opacity: 0;
    transform: translateY(25px);
    transition: opacity 0.3s 0.3s ease-in-out, transition 0.3s 0.3s ease-in-out ;
}

.pricing-row.active{
    opacity: 1;
    transform: translateY(0);
}


@media screen and (max-width:768px) {
    .pricing-main-bx{
        margin-bottom: 25px;
    }
}

@media screen and (max-width:576px) {
    .pricing-included-bx.check-list-bx .clb-item {
        width: 100%;
        flex: 100%;
    }
}





.demo-home-page-section{
    position: relative;
    /* padding-top: 45%; */
    overflow: hidden;
    height: 100vh;
}

.demo-home-page-section > img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.demo-home-page-section > img:nth-child(2){
    display: none;
}

.demo-home-page-section .textlayout-main-bx{
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 6% 17%;
}

.demo-home-page-section .textlayout-main-bx .tlm-btns{
    position: absolute;
    bottom: 20%;
    right: 17%;
}

@media screen and (max-width:992px) {
    .demo-home-page-section {
        padding-top: 0;
        height: 500px;
    }
}

@media screen and (max-width:768px) {
    .demo-home-page-section > img{
        object-position: center;
        object-fit: cover;
    } 

    .demo-home-page-section .textlayout-main-bx{
        padding: initial;
        max-width: 568px;
        width: 100%;
        margin: auto;
        padding-left: 24px;
        padding-right: 40px;
    }

    .demo-home-page-section .textlayout-main-bx .tlm-btns{
        right: 10px;
        bottom: 20%;
    }
}

@media screen and (max-width:600px) {
    .demo-home-page-section > img:nth-child(1){
        display: none;
    } 
    .demo-home-page-section > img:nth-child(2){
        display: block;
        object-fit: initial;
    } 
}

.dfgsdrhfgdrh{
    position: relative;
}

.dfgsdrhfgdrh input{
    position: relative;
    height: 32px;
    border-radius: 50px;
    border: none;
    outline: none;
    background-color: #929292;
    /* box-shadow: 2px 3px 3px #646464; */
    color: white;
    padding-left: 32px;
    width: 32px;
    transition: all 0.3s ease-in-out;
}

.dfgsdrhfgdrh input{
    width: 180px;
    padding-right: 10px;
}

.dfgsdrhfgdrh svg{
    color: white;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px;
    z-index: 1;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}

.dfgsdrhfgdrh:hover svg{
    opacity: 1;

}

@media screen and (max-width:1060px) {
    .dfgsdrhfgdrh input{
        width: 100px;
    }
}

@media screen and (max-width:992px) {
    .dfgsdrhfgdrh input{
        width: 180px;
    }
}

@media screen and (max-width:480px) {
    .dfgsdrhfgdrh{
        display: none;
    }
}