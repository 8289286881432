/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last  1024 versions
*/

/* --- Footer Css --- */

footer.main-footer{
    position: relative;
    z-index: 1;
    background-image: url("../img/bgimg/footerimg.jpg");
    background-position: center -100px;
    background-size: cover;
    background-repeat: repeat-y;
}

footer.main-footer:after{
    content: "";
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

footer.main-footer > div{
    /* padding: 0 15px; */
    max-width: 1200px;
    filter: invert();
    position: relative;
    z-index: 1;
}

.container-lg.cl-footer{
    max-width: 1920px;
    padding-left: 15px;
    padding-right: 15px;
}

/* --- Footer logo Css --- */
.footer-logo img{
    width: 130px;
    margin-bottom: 15px;
    cursor: pointer;
}

/* --- Footer Heading Css --- */
.footer-heading{
    color: #000000E0;
    font-size: 14px;
    font-weight: 600;
    margin-top: 0px;
    margin-bottom: 8px;
}

.footer-heading img{
    width: 20px;
    height: 10px;
    object-position: left center;
    object-fit: contain;
    margin-top: -5px;
    margin-bottom: -5px;
}

.footer-sub-heading{
    color: #000000b8;
    font-size: 12px;
    font-weight: 500;
    margin-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}


/* --- footer Links Css --- */
.footer-links-bx{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.footer-links-bx .footer-link{
    position: relative;
    /* color: #000000b8; */
    color: var(--color2);
    font-size: 13px;
    font-weight: 500;
    margin-right: 0;
    margin-top: 0px;
    margin-bottom: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.footer-links-bx .footer-link:hover {
    color: var(--color1);
}

.footer-links-bx .footer-link.active {
    color: var(--color1);
}

/* --- Footer Links Bx Start --- */
@media screen and (min-width:576px) {
    
    .footer-links-bx.flb-style-2{
        flex-direction: row;
    }
    
    .footer-links-bx.flb-style-2 .footer-link{
        width: 50%;
        flex: 0 0 50%;
    }
}
/* --- Footer Links Bx End --- */



/* --- Footer copyright & mede-with section Css --- */
.footer-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 15px 0 15px;
    border-top: 1px solid var(--border-color2);
    margin-top: 25px;
}

.copyright {
    color: #0000008F;
    font-size: 13px;
    font-weight: 500;
}

.mede-with {
    color: #0000008F;
    font-size: 13px;
    margin: 0;
    font-weight: 500;
}

.mede-with a {
    color:  var(--color2);
    font-weight: 500;
    opacity: 0.8;
}

.mede-with:hover a,
.mede-with a:hover {
    opacity: 1;
    color: var(--color1);
}

.icons-box-made {
    width: 20px;
    height: 14px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    top: 3px;
}

.icons-box-made .fa{
    position: absolute;
}

.mede-with .fa:nth-child(1) {
    color: var(--color2);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
}

.mede-with .fa:nth-child(2) {
    color: var(--color2);
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
    -webkit-transform: scale(0);
       -moz-transform: scale(0);
        -ms-transform: scale(0);
         -o-transform: scale(0);
            transform: scale(0);
}

.mede-with:hover .fa:nth-child(1) {
    opacity: 0;
}

.mede-with:hover .fa:nth-child(2) {
    opacity: 1;
    color: var(--color1);
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}


@media screen and (max-width:700px) {
    .footer-bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .copyright {
        margin-bottom: 10px;
    }
}


@media screen and (max-width:575px) {

    .footer-links-bx{
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        margin: 0 -8px;
    }

    .footer-links-bx .footer-link{
        display: inline-block;
        padding: 5px 10px 5px 10px;
    }
    
    .footer-links-bx .footer-link::after{
        content: " ";
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
           -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
             -o-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 1px;
        height: 50%;
        background-color: var(--background-color1);
        opacity: 0.3;
    }
    
    .footer-links-bx .footer-link:last-child::after {
        content: none;
    }
}


/* --- Footer Contact Info Css --- */
.footer-contact-info{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 0px;
    margin-top: 0px;
    font-size: 14px;
    white-space: nowrap;
    color: #000000b8;
    padding-right: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info img{
    position: relative;
    top: 1px;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    opacity: 0.7;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.footer-contact-info:hover{
    color: var(--color1);
}

.whatsapp-link .footer-contact-info img,
.footer-contact-info:hover img{
    opacity: 1;
}

/* --- Footer Bottom Css --- */

/* --- End Footer Css --- */